import Home from "./components/views/Home";
import Login from "./components/views/Login";
import Logout from "./components/views/Logout";
import MovedToBookkeepingReceipts from "./components/views/MovedToBookkeepingReceipts";
import NewReceipts from "./components/views/NewReceipts";
// import WakkaKuitti from "./components/views/WakkaKuitti";
import { LicenseRequirementType, WakkaLicenses } from "./types/WakkaLicenses";

const AppRoutes = [
  {
    index: true,
    requiresAuth: false,
    element: <Home />,
  },
  {
    path: "/login",
    requiresAuth: false,
    element: <Login />,
  },
  {
    path: "/logout",
    requiresAuth: false,
    element: <Logout />,
  },
  // This section will be added when the application expands.
  /*   {
    path: "/wakkaKuitti",
    requiresAuth: true,
    requiresLicense: {
      requirementType: LicenseRequirementType.Any,
      licenses: [WakkaLicenses.WakkaKuitti],
    },
    element: <WakkaKuitti />,
  }, */
  {
    path: "/newReceipts",
    requiresAuth: true,
    requiresLicense: {
      requirementType: LicenseRequirementType.Any,
      licenses: [WakkaLicenses.WakkaKuitti],
    },
    element: <NewReceipts />,
  },
  {
    path: "/movedToBookkeepingReceipts",
    requiresAuth: true,
    requiresLicense: {
      requirementType: LicenseRequirementType.Any,
      licenses: [WakkaLicenses.WakkaKuitti],
    },
    element: <MovedToBookkeepingReceipts />,
  },
];

export default AppRoutes;
