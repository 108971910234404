import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import { logout } from '../../store/slices/loginSlice';
import { getIdentityConfiguration, getLoginType, LoginType, logout as authLogout } from '../../utils/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { persistor } from '../../store/store';
import { clearUserDataCache } from '../../services/userService';
import Loader from '../components/Loader';

function Logout(props: any) {

  const { t } = useTranslation(['texts', 'errors']);
  const showLoginError = useAppSelector((state) => state.login.showLoginError);
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const toggleLoading = () => setLoading(prevState => !prevState);

  useEffect(() => {
    const identityConfiguration = getIdentityConfiguration();
    const loginType = getLoginType();
    if (loginType === LoginType.AuthCodePKCE) {
      const idToken = localStorage.getItem("mobiwakka_id_token");
      if (idToken) {
        toggleLoading();
        clearUserDataCache().finally(() => {
          toggleLoading();
          persistor.flush();
          persistor.purge();
          localStorage.clear();
          if (showLoginError) {
            localStorage.setItem("mobiwakka_showLogoutError", "1");
          }
          authLogout(idToken, identityConfiguration);
        });
      } else {
        if (localStorage.getItem("mobiwakka_showLogoutError")) {
          localStorage.removeItem("mobiwakka_showLogoutError");
          toast.error<unknown>(t("ERR_LOGIN_FAILED", { ns: "errors" }));
        } else {
          toast.success<unknown>(t("LOGOUT_SUCCESS"), { autoClose: 2000 });
        }
      }
    } else {
        toggleLoading();
      clearUserDataCache().finally(() => {
        toggleLoading();
        dispatch(logout());
        toast.success<unknown>(t("LOGOUT_SUCCESS"), { autoClose: 2000 });
      });
    }
  });

  if (isLoading) {
    return (
      <Loader />
    )
  }

  return (
    <Navigate to="/" replace={true} />
  )
    
}

export default Logout;