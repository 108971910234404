import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Spinner } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Navigate, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ReceivedElectricReceiptStatus } from "../../generated/tsClient";
import { logout } from "../../store/slices/loginSlice";
import CommonTakePhotoHeader from "../components/CommonTakePhotoHeader";
import CreateMultiPageReceipt from "../components/CreateMultiPageReceipt";
import { IReceiptImage } from "../../store/slices/receiptSlice";

function Home(props: any) {
  const { t } = useTranslation(["texts", "errors"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector((state) => state.login.authenticated);
  const userData = useAppSelector((state) => state.login.userData);
  const receivedElectricReceipts = useAppSelector(
    (state) => state.receipts.receivedElectricReceipts
  );
  const isLoading = useAppSelector((state) => state.receipts.isLoading);

  const newElectricReceipts = receivedElectricReceipts.filter(
    (r) => r.status === ReceivedElectricReceiptStatus.New
  );
  const movedToBookkeepingElectricReceipts = receivedElectricReceipts.filter(
    (r) => r.status === ReceivedElectricReceiptStatus.Transferred
  );
  const [receiptImage, setReceiptImage] = useState<IReceiptImage | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("state") === "pkceLogout") {
      dispatch(logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticated && userData) {
    return (
      <Container className="py-3 px-1">
        <CommonTakePhotoHeader title="" receiptImageParam={receiptImage} />
        <Container>
          <Container className="d-flex p-0">
            <Box
              sx={{
                width: "130px",
                background: "#D6EAF8",
                fontWeight: "bold",
                marginTop: "25px",
                padding: "10px 10px 15px 10px",
                borderRadius: "3px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("newReceipts")}
            >
              <div className="homeBoxHeader">{t("HOME_NEW_RECEIPTS")}</div>
              {isLoading && (
                <Spinner
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  color="light"
                />
              )}
              {!isLoading && (
                <div className="text-primary mt-2">
                  {newElectricReceipts.length}
                </div>
              )}
            </Box>
            <Box
              sx={{
                width: "130px",
                background: "#D6EAF8",
                fontWeight: "bold",
                marginTop: "25px",
                marginLeft: "15px",
                padding: "10px 10px 10px 10px",
                borderRadius: "3px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("movedToBookkeepingReceipts")}
            >
              <div className="homeBoxHeader">
                {t("HOME_MOVED_TO_BOOKKEEPING")}
              </div>
              {isLoading && (
                <Spinner
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  color="light"
                />
              )}
              {!isLoading && (
                <div className="text-primary mt-2">
                  {movedToBookkeepingElectricReceipts.length}
                </div>
              )}
            </Box>
          </Container>
          <CreateMultiPageReceipt setReceiptImage={setReceiptImage} />
        </Container>
      </Container>
    );
  } else {
    return <Navigate to="/login" replace={true} />;
  }
}

export default Home;
