import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from '../../i18n';

export interface IAppState {
  language: string,
  loading: boolean,
  error: string | null,
  headerVersion: number,
  showSettingsModal: boolean
}

const initialState: IAppState = {
  language: 'fi',
  loading: false,
  error: null,
  headerVersion: 0,
  showSettingsModal: false
}

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      i18n.changeLanguage(action.payload);
      state.language = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      console.error(action.payload);
    },
    clearError: (state) => {
      state.error = null;
    },
    reRenderHeader: (state) => {
      state.headerVersion++;
    },
    setShowSettingsModal: (state, action: PayloadAction<boolean>) => {
      state.showSettingsModal = action.payload;
    }
  }
})

export const { setLanguage, startLoading, stopLoading, setError, clearError, reRenderHeader, setShowSettingsModal } = appSlice.actions;

export default appSlice.reducer;
