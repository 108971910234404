import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookkeepingDto, BookkeepingSettingsDto } from '../../generated/tsClient';

export interface IBookkeepingState {

    bookkeeping: BookkeepingDto | null,
    bookkeepings: BookkeepingDto[],
    bookkeepingSettings: BookkeepingSettingsDto | null,
    bookkeepingSelectedInSettings: BookkeepingDto | null
}

const initialState: IBookkeepingState = {
    bookkeeping: null,
    bookkeepings: [],
    bookkeepingSettings: null,
    bookkeepingSelectedInSettings: null,
}

export const bookkeepingSlice = createSlice({
  name: 'bookkeeping',
  initialState: initialState,
  reducers: {
        setBookkeepings: (state, action: PayloadAction<BookkeepingDto[]>) => {
          state.bookkeepings = action.payload;
        },
        setBookkeeping: (state, action: PayloadAction<BookkeepingDto>) => {
            state.bookkeeping = action.payload;
        },
        setBookkeepingSettings: (state, action: PayloadAction<BookkeepingSettingsDto>) => {
            state.bookkeepingSettings = action.payload;
        },
        setBookkeepingSelectedInSettings: (state, action: PayloadAction<BookkeepingDto>) => {
            state.bookkeepingSelectedInSettings = action.payload;
        }
  }
})

export const { setBookkeepings, setBookkeeping, setBookkeepingSettings, setBookkeepingSelectedInSettings } = bookkeepingSlice.actions;

export default bookkeepingSlice.reducer;
