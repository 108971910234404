import { Navigate, Route, Routes } from "react-router-dom";
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { Fragment, ReactNode, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { clearError, setLanguage } from './store/slices/appSlice';
import { logout, setAuthenticated, setNotAuthenticated } from './store/slices/loginSlice';
import './custom.css';
import { checkTokenIssuer, getAccessToken } from './utils/auth';
import { fetchUserData } from './services/userService';
import { useTranslation } from 'react-i18next';
import { toast, ToastOptions } from 'react-toastify';
import { IUserDataDto } from './generated/tsClient';
import { verifyLicenses } from './utils/common';
import { LicenseRequirements } from './types/WakkaLicenses';

interface ProtectedRouteProps {
  isAuthenticated: boolean,
  requiresLicense: LicenseRequirements | undefined,
  userData: IUserDataDto | null,
  errorText: string,
  children: ReactNode
}

const ProtectedRoute = ({ isAuthenticated, requiresLicense, userData, errorText, children }: ProtectedRouteProps) => {
  if (!isAuthenticated) {
    return (
      <Fragment>
        <Navigate to="/" replace />
      </Fragment>
    );
  }

  let hasLicenses = false;
  if (requiresLicense) {
    hasLicenses = verifyLicenses(requiresLicense, userData);
  } else {
    hasLicenses = true;
  }

  if (!hasLicenses) {
    toast.error(errorText);
  }

  return (
    <Fragment>
      {hasLicenses ? children : <Navigate to="/" replace />}
    </Fragment>
  );
}

const ErrorNotifications = ({ error }: any) => {
  const dispatch = useAppDispatch();

  if (error) {

     const toastOptions: ToastOptions = { bodyStyle: { whiteSpace: 'pre-line' } };
     toast.error(error, toastOptions);
     dispatch(clearError());
  }

  return null;
}

function App(props: any) {

  const { t } = useTranslation(['texts', 'errors']);
  const [cookies] = useCookies(['language']);
  const isAuthenticated = useAppSelector((state) => state.login.authenticated);
  const userData = useAppSelector((state) => state.login.userData);
  const error = useAppSelector((state) => state.app.error);
  const dispatch = useAppDispatch();

  useEffect(() => {

    let lang = cookies.language;
    if (lang) {
      dispatch(setLanguage(lang));
    } else {
      dispatch(setLanguage('fi'));
    }

    const params = new URLSearchParams(window.location.search);
    if (params.get('state') === 'pkceLogout') {
      dispatch(logout());
      return;
    }
    if (params.get('state') === 'loginFailed') {
      toast.error<unknown>(t("ERR_LOGIN_FAILED", { ns: "errors" }));
    }
    if (params.has('code') || params.get('state')) {
      return;
    }

    const getToken = async () => {
      const token = await getAccessToken();
      if (token && checkTokenIssuer(token)) {
        var user: IUserDataDto | undefined;
        if (userData) {
          user = userData;
        } else {
          await fetchUserData()
            .then((response) => {
              user = response;
            })
            .catch(() => {
              user = undefined;
            });
        }
        if (user) {
          dispatch(setAuthenticated(user));
        } else {
          dispatch(setNotAuthenticated());
        }
      } else {
        dispatch(setNotAuthenticated());
      }
    }
    getToken();

    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Layout>
      <ErrorNotifications error={error} />
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          if (route.requiresAuth || route.requiresLicense) {
            return <Route key={index} {...rest} element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                requiresLicense={route.requiresLicense}
                userData={userData}
                errorText={t('ERR_MISSING_LICENSE', { ns: 'errors' })}>
                {element}
              </ProtectedRoute>}
            />;
          } else {
            return <Route key={index} {...rest} element={element} />;
          }
        })}
      </Routes>
    </Layout>
  )
}

export default App;