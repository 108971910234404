import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import texts_en from './resources/texts/en.json';
import texts_fi from './resources/texts/fi.json';
import texts_sv from './resources/texts/sv.json';
import errors_en from './resources/errors/en.json';
import errors_fi from './resources/errors/fi.json';
import errors_sv from './resources/errors/sv.json';


const resources = {
  en: {
    texts: texts_en,
    errors: errors_en
    },
    fi: {
        texts: texts_fi,
        errors: errors_fi
    },
    sv: {
        texts: texts_sv,
        errors: errors_sv
    }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    ns: ['texts', 'errors'],
    defaultNS: 'texts',
    keySeparator: false,
    lng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
