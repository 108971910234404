import {
    Container,
    Navbar, NavbarBrand, NavItem
} from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import './Header.css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setShowSettingsModal } from '../../store/slices/appSlice';
import { setBookkeeping } from '../../store/slices/bookkeepingSlice';
import { setSelectedPeriod } from '../../store/slices/accountingPeriodsSlice';
import { fetchBookkeepingSettings } from '../../services/bookkeepingService';
import { fetchAccountingPeriods } from '../../services/accountingPeriodsService';
import { BookkeepingDto } from '../../generated/tsClient';
import { Tooltip } from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsModal from '../modals/SettingsModal';
import { fetchReceivedElectricReceipts } from '../../services/receiptService';
import { setIsLoading } from '../../store/slices/receiptSlice';

function Header(props: any) {

    const dispatch = useAppDispatch();
    const { t } = useTranslation('texts');
    const isAuthenticated = useAppSelector((state) => state.login.authenticated);
    const headerVersion = useAppSelector((state) => state.app.headerVersion);
    const userData = useAppSelector((state) => state.login.userData);
    
    const bookkeepings = useAppSelector((state) => state.bookkeeping.bookkeepings);
    const selectedBookkeeping = useAppSelector((state) => state.bookkeeping.bookkeeping);


    
    useEffect(() => {
        
        if (isAuthenticated && !selectedBookkeeping ) {

            let selectedBookkeeping: BookkeepingDto = bookkeepings[0];

            if (userData?.defaultBookkeeping !== undefined && userData?.defaultBookkeeping > 0) {
            
                selectedBookkeeping = bookkeepings.find(p => p.id === userData.defaultBookkeeping) ?? bookkeepings[0] ;
            } 

            if (selectedBookkeeping) {

                dispatch(setIsLoading(true));
                dispatch(setBookkeeping(selectedBookkeeping));

                fetchAccountingPeriods(selectedBookkeeping.id?.toString() as string)
                .then(periods => {
                  dispatch(setSelectedPeriod(periods[0]));
                  
                  fetchReceivedElectricReceipts(
                    selectedBookkeeping?.id?.toString() as string,
                    periods?.[0]?.periodId?.toString() as string
                  );
                });

                fetchBookkeepingSettings(selectedBookkeeping.id?.toString() as string);
                dispatch(setIsLoading(false));
            }           
        }    

        // eslint-disable-next-line
    }, [isAuthenticated, bookkeepings, headerVersion]);

  return (
    <header className="header-border">
      <Navbar id="navbar.mobiwakka" className="navbar-expand-sm">
        <NavbarBrand id="navbar.brand.homeicon" tag={Link} to="/">
          <img width="70" src="./img/mobiwakka2.png" alt="MobiWakka" />
        </NavbarBrand>

        {isAuthenticated && (
          <Container className="d-inline-flex p-0 col">
            <NavItem className="px-2 navbar-nav">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "text-dark navlinkactive" : "text-dark navlink"
                }
                to="/"
              >
                {t("NAV_WAKKAKUITTI")}
              </NavLink>
            </NavItem>
            {/** This section will be added when the application expands. */}
            {/* <NavItem className="px-2 navbar-nav">
                <NavLink className={({ isActive }) => isActive ? "text-dark navlinkactive" : 'text-dark navlink'} to="/wakkaKuitti">{t('NAV_WAKKAKUITTI')}</NavLink>
              </NavItem> */}
          </Container>
        )}

        <Container className="d-inline-flex flex-row-reverse p-2 col">
          {!isAuthenticated && (
            <NavItem className="navbar-nav">
              <NavLink className="text-dark navlink" to="/login">
                {t("NAV_LOGIN")}
              </NavLink>
            </NavItem>
          )}

          {isAuthenticated && (
            <Tooltip title={t("HEADER_SETTINGS")}>
              <SettingsOutlinedIcon
                role="button"
                onClick={() => dispatch(setShowSettingsModal(true))}
                fontSize="large"
              />
            </Tooltip>
          )}
        </Container>
      </Navbar>

      <SettingsModal />
    </header>
  );
}

export default Header;