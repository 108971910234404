import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ModalStatus } from "../../types/ModalStatusEnum";
import "dayjs/locale/fi";
import "dayjs/locale/sv";
import dayjs from "dayjs";
import {
  resetReceiptFormField,
  setModalStatus,
  setIsLoading,
  IReceiptImage,
} from "../../store/slices/receiptSlice";
import {
  createReceivedElectricReceiptFile,
  updateElectricReceipt,
} from "../../services/receiptService";
import {
  CreateReceivedElectricReceiptFileCommand,
  PaymentMethod,
  UpdateElectricReceiptCommand,
} from "../../generated/tsClient";
import { getAutoCloseTime } from "../../utils/common";
import { toast } from "react-toastify";
import ReceiptForm from "../components/ReceiptForm";
interface Props {
  isOpen: boolean;
  setReceiptImage: (receiptImage: IReceiptImage | null) => void;
  receiptImage: IReceiptImage | null;
}
function SaveReceiptModal({ isOpen, setReceiptImage, receiptImage }: Props) {
  const { t } = useTranslation(["texts", "errors"]);
  const dispatch = useAppDispatch();

  const language = useAppSelector((state) => state.app.language);
  const selectedBookkeeping = useAppSelector(
    (state) => state.bookkeeping.bookkeeping
  );
  const selectedAccountingPeriod = useAppSelector(
    (state) => state.accountingPeriods.selectedPeriod
  );
  const modalStatus = useAppSelector((state) => state.receipts.modalStatus);
  const receiptForm = useAppSelector((state) => state.receipts.receiptForm);

  const [priceStr, setPriceStr] = useState<string>("");

  const getLocalizationProviderLanguage = () => {
    return language === "fi" ? "fi" : "sv";
  };

  const onClose = () => {
    setPriceStr("");
    setReceiptImage(null);
    dispatch(resetReceiptFormField());
    dispatch(setModalStatus(ModalStatus.Closed));
  };

  const onCreateReceivedElectricReceiptFile = () => {
    if (!receiptForm.price) {
      toast.error<unknown>(t("ERR_RECEIPT_PRICE", { ns: "errors" }));
      return;      
    }
    const command = new CreateReceivedElectricReceiptFileCommand();
    command.bookkeepingId = selectedBookkeeping?.id;
    command.periodId = selectedAccountingPeriod?.periodId;
    command.paymentDate = dayjs(receiptForm.paymentDate).toDate();
    command.paymentMethod = receiptForm.paymentMethod ?? (0 as PaymentMethod);
    command.description = receiptForm.description ?? undefined;
    command.purchasePlace = receiptForm.purchasePlace ?? undefined;
    command.price = receiptForm.price;
    command.electricReceiptFileBase64 = receiptImage?.imageFileBase64;
    command.electricReceiptFileMimeType = receiptImage?.mimeType;

    dispatch(setIsLoading(true));
    createReceivedElectricReceiptFile(command)
      .then(() => {
        toast.success(`${t("SAVE_SUCCESS")}`, {
          autoClose: getAutoCloseTime(),
        });
        onClose();
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const onUpdateReceivedElectricReceiptFile = () => {
    if (
      !(receiptForm as UpdateElectricReceiptCommand).electricReceiptId ||
      !selectedBookkeeping?.id
    ) {
      toast.error(t("ERR_FAILED_TO_SAVE", { ns: "errors" }));
      return;
    }
    if (!receiptForm.price) {
      toast.error<unknown>(t("ERR_RECEIPT_PRICE", { ns: "errors" }));
      return;
    }
    const command = new UpdateElectricReceiptCommand();
    command.bookkeepingId = selectedBookkeeping?.id;
    command.electricReceiptId = (
      receiptForm as UpdateElectricReceiptCommand
    ).electricReceiptId;
    command.paymentDate = dayjs(receiptForm.paymentDate).toDate();
    command.paymentMethod = Number(receiptForm.paymentMethod) as PaymentMethod;
    command.electricReceiptPriceId = (
      receiptForm as UpdateElectricReceiptCommand
    ).electricReceiptPriceId;
    command.description = receiptForm.description;
    command.purchasePlace = receiptForm.purchasePlace;
    command.price = receiptForm.price;
    updateElectricReceipt(command)
      .then(() => {
        toast.success(`${t("SAVE_SUCCESS")}`, {
          autoClose: getAutoCloseTime(),
        });
        onClose();
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const onSave = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (modalStatus === ModalStatus.Create) {
      onCreateReceivedElectricReceiptFile();
    }
    if (modalStatus === ModalStatus.Edit) {
      onUpdateReceivedElectricReceiptFile();
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={getLocalizationProviderLanguage()}
    >
      <Modal isOpen={isOpen} id="modal.saveReceiptModal">
        <ModalHeader toggle={onClose}>
          {modalStatus === ModalStatus.Open
            ? t("RECEIPT_INFO")
            : t("WAKKAKUITTI_FILL_AND_SAVE_INFO")}
        </ModalHeader>
        <ModalBody>
          <ReceiptForm
            onSave={onSave}
            onClose={onClose}
            setPriceStr={setPriceStr}
            priceStr={priceStr}
            receiptImage={receiptImage}
          />
        </ModalBody>
      </Modal>
    </LocalizationProvider>
  );
}

export default SaveReceiptModal;
