import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateReceivedElectricReceiptFileCommand,
  ReceivedElectricReceiptDto,
  UpdateElectricReceiptCommand,
} from "../../generated/tsClient";
import { ModalStatus } from "../../types/ModalStatusEnum";

export interface IReceiptImage {
  imageFileName: string;
  imagePreview: string | undefined;
  imageFileBase64: string;
  mimeType: string;
}
export interface IReceiptImageWithDimensions extends IReceiptImage {
  width: number,
  height: number
}

export interface IReceiptFile {
  url: string;
  contentType: string;
  fileName?: string;
}

export interface IReceiptState {
  receivedElectricReceipt: ReceivedElectricReceiptDto | null;
  receivedElectricReceipts: ReceivedElectricReceiptDto[];
  receiptImage: IReceiptImage | null;
  receiptFile: IReceiptFile | null;
  saveReceiptModalStatus: ModalStatus;
  receiptForm: Partial<
    CreateReceivedElectricReceiptFileCommand | UpdateElectricReceiptCommand
  >;
  modalStatus: ModalStatus;
  isLoading: boolean;
  selectedReceipts: number[];
}

const initialState: IReceiptState = {
  receivedElectricReceipt: null,
  receivedElectricReceipts: [],
  receiptImage: null,
  receiptFile: null,
  saveReceiptModalStatus: ModalStatus.Closed,
  receiptForm: {},
  modalStatus: ModalStatus.Closed,
  isLoading: false,
  selectedReceipts: [],
};

export const accountingPeriodsSlice = createSlice({
  name: "receipt",
  initialState: initialState,
  reducers: {
    setReceivedElectricReceipt: (
      state,
      action: PayloadAction<ReceivedElectricReceiptDto>
    ) => {
      state.receivedElectricReceipt = action.payload;
    },

    setReceivedElectricReceipts: (
      state,
      action: PayloadAction<ReceivedElectricReceiptDto[]>
    ) => {
      state.receivedElectricReceipts = action.payload;
    },

    setReceiptImage: (state, action: PayloadAction<IReceiptImage>) => {
      state.receiptImage = action.payload;
    },

    openSaveReceiptModal: (state) => {
      state.saveReceiptModalStatus = ModalStatus.Open;
    },

    closeSaveReceiptModal: (state) => {
      state.saveReceiptModalStatus = ModalStatus.Closed;
      state.receiptImage = initialState.receiptImage;
    },

    resetInitialState: (state) => {
      state.receivedElectricReceipt = initialState.receivedElectricReceipt;
      state.receivedElectricReceipts = initialState.receivedElectricReceipts;
      state.saveReceiptModalStatus = initialState.saveReceiptModalStatus;
    },

    setReceiptFormField: (
      state,
      action: PayloadAction<
        Partial<
          | CreateReceivedElectricReceiptFileCommand
          | UpdateElectricReceiptCommand
        >
      >
    ) => {
      state.receiptForm = { ...state.receiptForm, ...action.payload };
    },

    resetReceiptFormField: (state) => {
      state.receiptForm = initialState.receiptForm;
      state.receiptFile = initialState.receiptFile;
      state.receiptImage = initialState.receiptImage;
    },

    setModalStatus: (state, action: PayloadAction<ModalStatus>) => {
      state.modalStatus = action.payload;
    },

    setReceiptFile: (state, action: PayloadAction<IReceiptFile>) => {
      state.receiptFile = action.payload;
    },

    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    updateElectricReceipts: (
      state,
      action: PayloadAction<ReceivedElectricReceiptDto>
    ) => {
      state.receivedElectricReceipts = state.receivedElectricReceipts.map(
        (receipt) =>
          receipt.electricReceiptId === action.payload.electricReceiptId
            ? action.payload
            : receipt
      );
    },

    addCreatedElectricReceipt: (
      state,
      action: PayloadAction<ReceivedElectricReceiptDto>
    ) => {
      state.receivedElectricReceipts = [
        ...state.receivedElectricReceipts,
        action.payload,
      ];
    },

    setSelectedReceipts: (state, action: PayloadAction<number[]>) => {
      state.selectedReceipts = action.payload;
    },
  },
});

export const {
  setReceivedElectricReceipt,
  setReceivedElectricReceipts,
  setReceiptImage,
  openSaveReceiptModal,
  closeSaveReceiptModal,
  resetInitialState,
  setReceiptFormField,
  setModalStatus,
  resetReceiptFormField,
  setReceiptFile,
  setIsLoading,
  updateElectricReceipts,
  setSelectedReceipts,
  addCreatedElectricReceipt,
} = accountingPeriodsSlice.actions;

export default accountingPeriodsSlice.reducer;
