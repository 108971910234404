import { sendPostRequest } from '../utils/axiosClient';
import { store } from '../store/store';
import { setToken } from '../store/slices/loginSlice';
import { base64Encode, decimalToHex, sha256Encode } from './common';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

export interface IIdentityConfiguration {
  authorizeUrl: string,
  tokenUrl: string,
  logoutUrl: string,
  clientId: string,
  clientSecret: string,
  scope: string,
  loginRedirectUrl: string,
  logoutRedirectUrl: string
}

export interface ITokenData {
  accessToken: string,
  refreshToken: string,
  idToken: string
}

export enum LoginType {
  None = 'None',
  ResourceOwnerPassword = 'ROPC',
  AuthCodePKCE = 'PKCE'
}

export const getLoginType = () => {
  switch (process.env.REACT_APP_LOGIN_TYPE as string) {
    case LoginType.AuthCodePKCE:
      return LoginType.AuthCodePKCE;
    case LoginType.ResourceOwnerPassword:
      return LoginType.ResourceOwnerPassword;
    default:
      return LoginType.None;
  }
}

export const checkTokenIssuer = (token: string) => {
  const identityConfiguration = getIdentityConfiguration();
  const issuer = identityConfiguration.issuer;
  let parsedToken = parseJwt(token);
  return parsedToken.iss === issuer;
}

export const getIdentityConfiguration = () => {
  let clientId = '';
  switch (getLoginType()) {
    case LoginType.AuthCodePKCE:
      clientId = process.env.REACT_APP_IDENTITY_CLIENT_ID_PKCE as string;
      break;
    case LoginType.ResourceOwnerPassword:
      clientId = process.env.REACT_APP_IDENTITY_CLIENT_ID_ROPC as string
      break;
    default: break;
  }
  return {
    authorizeUrl: process.env.REACT_APP_IDENTITY_AUTHORIZE_URL as string,
    tokenUrl: process.env.REACT_APP_IDENTITY_TOKEN_URL as string,
    logoutUrl: process.env.REACT_APP_IDENTITY_LOGOUT_URL as string,
    clientId: clientId,
    clientSecret: process.env.REACT_APP_IDENTITY_CLIENT_SECRET as string,
    issuer: process.env.REACT_APP_IDENTITY_ISSUER as string,
    scope: process.env.REACT_APP_IDENTITY_SCOPE as string,
    loginRedirectUrl: process.env.REACT_APP_IDENTITY_LOGIN_REDIRECT_URL as string,
    logoutRedirectUrl: process.env.REACT_APP_IDENTITY_LOGOUT_REDIRECT_URL as string,
  };
}

function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const getAccessToken = async () => {
  let token = localStorage.getItem("mobiwakka_access_token");
  
  if (!token) {
    console.error("Failed to get access token");
    return null;
  }

  let parsedToken = parseJwt(token);
  let tokenExpiration = parsedToken.exp;
  let isExpired = Date.now() >= tokenExpiration * 1000;

  if (isExpired) {
    const release = await mutex.acquire();
    try {
      token = localStorage.getItem("mobiwakka_access_token");
      if (!token) {
        console.error("Failed to get access token");
        return null;
      }
      parsedToken = parseJwt(token);
      tokenExpiration = parsedToken.exp;
      isExpired = Date.now() >= tokenExpiration * 1000;
      if (!isExpired) {
        return token;
      }

      const refreshToken = localStorage.getItem("mobiwakka_refresh_token");
      if (!refreshToken) {
        console.error("Missing refresh token");
        return null;
      }

      const identityConfiguration = getIdentityConfiguration();
      const newToken = await refreshAccessToken(refreshToken, identityConfiguration);

      if (newToken?.accessToken) {
        store.dispatch(setToken(newToken));
        return newToken.accessToken;
      } else {
        console.error("Access token refresh failed");
      }
    } finally {
      release();
    }
  }

  if (!token) {
    console.error("Failed to get access token");
  }

  return token;
}

export const refreshAccessToken = async (refreshToken: string, identityConfiguration: IIdentityConfiguration) => {

  let token: ITokenData = {
    accessToken: '',
    refreshToken: '',
    idToken: ''
  }

  const body = new URLSearchParams({
    'grant_type': 'refresh_token',
    'client_id': identityConfiguration.clientId,
    'client_secret': identityConfiguration.clientSecret,
    'refresh_token': refreshToken
  });

  await sendPostRequest(identityConfiguration.tokenUrl, body, 'application/x-www-form-urlencoded', false)
    .then(response => {
      const isJson = response.headers['content-type']?.includes('application/json');
      const data = isJson ? response.data : null;
      if (data) {
        token.accessToken = data['access_token'];
        token.refreshToken = data['refresh_token'];
        token.idToken = data['id_token'] ?? '';
      }
    })
    .catch(error => {
      console.error("Token refresh error: " + JSON.stringify(error));
    });

  return token;
}

function generateCodeVerifier() {
  var array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, decimalToHex).join("");
}

async function generateCodeChallengeFromVerifier(verifier: string) {
  var hashedVerifier = await sha256Encode(verifier);
  var challenge = base64Encode(hashedVerifier);
  return challenge;
}

export const startAuthCodeFlow = async (identityConfiguration: IIdentityConfiguration) => {
  const verifier = generateCodeVerifier();
  const challenge = await generateCodeChallengeFromVerifier(verifier)

  const body = new URLSearchParams({
    'client_id': identityConfiguration.clientId,
    'response_type': 'code',
    'scope': identityConfiguration.scope,
    'redirect_uri': identityConfiguration.loginRedirectUrl,
    'code_challenge_method': 'S256',
    'code_challenge': challenge
  });
  // TODO: login page localization with ui_locales

  return {
    authorizeUrl: `${identityConfiguration.authorizeUrl}?${body.toString()}`,
    verifier: verifier
  };
}
export const getAccessTokenWithAuthCode = async (authCode: string, verifier: string, identityConfiguration: IIdentityConfiguration) => {

  let token: ITokenData = {
    accessToken: '',
    refreshToken: '',
    idToken: ''
  }

  const body = new URLSearchParams({
    'grant_type': 'authorization_code',
    'client_id': identityConfiguration.clientId,
    'client_secret': identityConfiguration.clientSecret,
    'redirect_uri': identityConfiguration.loginRedirectUrl,
    'code': authCode,
    'code_verifier': verifier
  });

  // TODO url from .env
  await sendPostRequest(identityConfiguration.tokenUrl, body, 'application/x-www-form-urlencoded', false)
    .then(response => {
      const isJson = response.headers['content-type']?.includes('application/json');
      const data = isJson ? response.data : null;
      if (data) {
        token.accessToken = data['access_token'];
        token.refreshToken = data['refresh_token'];
        token.idToken = data['id_token'];
      }
    })
    .catch(error => {
      console.error("Login error: " + JSON.stringify(error));
    })

  return token;
}

export const logout = (idToken: string, identityConfiguration: IIdentityConfiguration) => {
  const logoutUrl = `${identityConfiguration.logoutUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${identityConfiguration.logoutRedirectUrl}&state=pkceLogout`;
  localStorage.removeItem('mobiwakka_id_token');
  localStorage.removeItem("mobiwakka_access_token");

  window.location.replace(logoutUrl);
}

export const loginWithCredentials = async (username: string, password: string, identityConfiguration: IIdentityConfiguration) => {

  let token: ITokenData = {
    accessToken: '',
    refreshToken: '',
    idToken: ''
  }

  const body = new URLSearchParams({
    'username': username,
    'password': password,
    'client_id': identityConfiguration.clientId,
    'client_secret': identityConfiguration.clientSecret,
    'grant_type': 'password',
    'scope': identityConfiguration.scope
  });

  await sendPostRequest(identityConfiguration.tokenUrl, body, 'application/x-www-form-urlencoded', false)
    .then(response => {
      const isJson = response.headers['content-type']?.includes('application/json');
      const data = isJson ? response.data : null;
      if (data) {
        token.accessToken = data['access_token'];
        token.refreshToken = data['refresh_token'];
      }
    })
    .catch(error => {
      console.error("Login error: " + JSON.stringify(error));
    });

  return token;
}
