import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { useCookies } from "react-cookie";
import "dayjs/locale/fi";
import "dayjs/locale/sv";
import dayjs from "dayjs";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import { setLanguage, setShowSettingsModal } from "../../store/slices/appSlice";
import { setBookkeeping } from "../../store/slices/bookkeepingSlice";
import { fetchAccountingPeriods } from "../../services/accountingPeriodsService";
import { setSelectedPeriod } from "../../store/slices/accountingPeriodsSlice";
import { fetchBookkeepingSettings } from "../../services/bookkeepingService";
import { fetchReceivedElectricReceipts } from "../../services/receiptService";
import { setIsLoading } from "../../store/slices/receiptSlice";
import { formatDateToString } from "../../utils/common";

function SettingsModal(props: any) {
  const { t } = useTranslation(["texts", "errors"]);
  const [cookies, setCookie] = useCookies(["language"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const language = useAppSelector((state) => state.app.language);
  const showSettingsModal = useAppSelector(
    (state) => state.app.showSettingsModal
  );
  const bookkeepings = useAppSelector(
    (state) => state.bookkeeping.bookkeepings
  );
  const selectedBookkeeping = useAppSelector(
    (state) => state.bookkeeping.bookkeeping
  );
  const accountingPeriods = useAppSelector(
    (state) => state.accountingPeriods.periods
  );
  const selectedAccountingPeriod = useAppSelector(
    (state) => state.accountingPeriods.selectedPeriod
  );

  const toggleSettingsModal = () => {
    dispatch(setShowSettingsModal(!showSettingsModal));
  };

  const selectBookkeeping = async (bookkeeping: any) => {
    const selectedBookkeeping = bookkeepings.find(
      (b) => b.id?.toString() === bookkeeping
    );

    if (selectedBookkeeping) {
      dispatch(setIsLoading(true));
      dispatch(setBookkeeping(selectedBookkeeping));

      fetchAccountingPeriods(selectedBookkeeping.id?.toString() as string).then(
        (periods) => {
          dispatch(setSelectedPeriod(periods[0]));
          fetchReceivedElectricReceipts(
            selectedBookkeeping?.id?.toString() as string,
            periods[0]?.periodId?.toString() as string
          );
        }
      );

      fetchBookkeepingSettings(selectedBookkeeping.id?.toString() as string);

      dispatch(setIsLoading(false));
    }
  };

  const handleBookkeepingChange = (event: SelectChangeEvent) => {
    selectBookkeeping(event.target.value);
  };

  const selectAccountingPeriod = async (period: any) => {
    const chosenAccountingPeriod = accountingPeriods.find(
      (p) => p.periodId?.toString() === period
    );

    if (selectedBookkeeping && chosenAccountingPeriod) {
      dispatch(setSelectedPeriod(chosenAccountingPeriod));
      dispatch(setIsLoading(true));

      fetchReceivedElectricReceipts(
        selectedBookkeeping?.id?.toString() as string,
        chosenAccountingPeriod?.periodId?.toString() as string
      ).finally(() => dispatch(setIsLoading(false)));
    }
  };

  const handleAccountingPeriodChange = (event: SelectChangeEvent) => {
    selectAccountingPeriod(event.target.value);
  };

  const selectLanguage = (language: any) => {
    dispatch(setLanguage(language));
    setCookie("language", language, { path: "/", expires: new Date("9999") });
  };

  const handleLanguageChange = (event: SelectChangeEvent) => {
    selectLanguage(event.target.value);
  };

  const onLogout = () => {
    dispatch(setShowSettingsModal(false));
    navigate("/logout");
  };

  return (
    <Modal isOpen={showSettingsModal} toggle={toggleSettingsModal} size="m">
      <ModalHeader toggle={toggleSettingsModal}>
        {t("HEADER_SETTINGS")}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <InputLabel
              sx={{ marginLeft: "1px", marginBottom: "5px", color: "#000000" }}
              shrink={false}
              id="label.settings.bookkeeping"
            >
              {t("LABEL_BOOKKEEPING")}
            </InputLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              label="label.settings.bookkeeping"
              value={
                selectedBookkeeping?.id?.toString() ?? t("SELECT_BOOKKEEPING")
              }
              onChange={handleBookkeepingChange}
              fullWidth={true}
              notched={false}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#99C2FF",
                  borderWidth: "2px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#99C2FF",
                },
              }}
              id="bookkeeping-select"
            >
              {bookkeepings.map((bookkeeping) => (
                <MenuItem
                  key={bookkeeping.id?.toString()}
                  value={bookkeeping.id?.toString()}
                >
                  {bookkeeping.description}
                </MenuItem>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <InputLabel
              sx={{
                marginLeft: "1px",
                marginTop: "10px",
                marginBottom: "5px",
                color: "#000000",
              }}
              shrink={false}
              id="label.settings.accountingperiod"
            >
              {t("LABEL_ACCOUNTING_PERIOD")}
            </InputLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              label="label.settings.accountingperiod"
              value={
                selectedAccountingPeriod?.periodId?.toString() ??
                t("SELECT_ACCOUNTING_PERIOD")
              }
              onChange={handleAccountingPeriodChange}
              fullWidth={true}
              notched={false}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#99C2FF",
                  borderWidth: "2px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#99C2FF",
                },
              }}
              id="accountingperiod-select"
            >
              {accountingPeriods.map((accountingPeriod) => (
                <MenuItem
                  key={accountingPeriod.periodId?.toString()}
                  value={accountingPeriod.periodId?.toString()}
                >
                  {`${accountingPeriod.name} (${formatDateToString(
                    dayjs(accountingPeriod.startDate).toDate()
                  )} - ${formatDateToString(
                    dayjs(accountingPeriod.endDate).toDate()
                  )})`}
                </MenuItem>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <InputLabel
              sx={{
                marginLeft: "1px",
                marginTop: "10px",
                marginBottom: "5px",
                color: "#000000",
              }}
              shrink={false}
              id="label.settings.language"
            >
              {t("LABEL_LANGUAGE")}
            </InputLabel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              label="label.settings.language"
              value={language}
              onChange={handleLanguageChange}
              fullWidth={true}
              notched={false}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#99C2FF",
                  borderWidth: "2px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#99C2FF",
                },
              }}
              id="language-select"
            >
              <MenuItem key="fi" value="fi">
                Suomi
              </MenuItem>
              <MenuItem key="sv" value="sv">
                Svenska
              </MenuItem>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-row-reverse mt-4">
            <div
              className="d-flex flex-row-reverse"
              onClick={onLogout}
              role="button"
            >
              <Tooltip title={t("NAV_LOGOUT")}>
                <LogoutSharpIcon role="button" fontSize="large" />
              </Tooltip>
              <div className="pt-1 px-2">{t("NAV_LOGOUT")}</div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default SettingsModal;
