import jsPDF from "jspdf";
import { IReceiptImageWithDimensions } from "../store/slices/receiptSlice";

const A4_DIMENSIONS = {
  width: 210,
  height: 297,
};
const A4_RATIO = A4_DIMENSIONS.width / A4_DIMENSIONS.height;

export const generatePdfFromImages = (images: IReceiptImageWithDimensions[]) => {
  const doc = new jsPDF();
  doc.deletePage(1);

  images.forEach((image: any) => {
    const imageDimensions = getPagedImageDimensions(image.width, image.height);

    doc.addPage();
    doc.addImage(
      image.imagePreview as string,
      image.mimeType.split("/")[1],
      (A4_DIMENSIONS.width - imageDimensions.width) / 2,
      (A4_DIMENSIONS.height - imageDimensions.height) / 2,
      imageDimensions.width,
      imageDimensions.height
    );
  });

  const pdfBlob = doc.output("blob");

  return pdfBlob;
}

const getPagedImageDimensions = (width: number, height: number) => {
  const isLandscapeImage = width >= height;

  if (isLandscapeImage) {
    return {
      width: A4_DIMENSIONS.width,
      height:
        A4_DIMENSIONS.width / (width / height),
    };
  }

  const imageRatio = width / height;
  if (imageRatio > A4_RATIO) {
    const imageScaleFactor =
      (A4_RATIO * height) / width;

    const scaledImageHeight = A4_DIMENSIONS.height * imageScaleFactor;

    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }

  return {
    width: A4_DIMENSIONS.height / (height / width),
    height: A4_DIMENSIONS.height,
  };
};
