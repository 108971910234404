import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/hooks";
import TakePhoto from "../components/TakePhoto";
import SaveReceiptModal from "../modals/SaveReceiptModal";
import { ModalStatus } from "../../types/ModalStatusEnum";
import { IReceiptImage } from "../../store/slices/receiptSlice";
import { useEffect, useState } from "react";
interface Props {
  title: string;
  receiptImageParam: IReceiptImage | null;
}
const CommonTakePhotoHeader = ({ title, receiptImageParam }: Props) => {
  const { t } = useTranslation(["texts", "errors"]);
  const selectedBookkeeping = useAppSelector(
    (state) => state.bookkeeping.bookkeeping
  );
  const selectedAccountingPeriod = useAppSelector(
    (state) => state.accountingPeriods.selectedPeriod
  );
  const modalStatus = useAppSelector((state) => state.receipts.modalStatus);
  const [receiptImage, setReceiptImage] = useState<IReceiptImage | null>(null);

  useEffect(() => {
    if (receiptImageParam) {
      setReceiptImage(receiptImageParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptImageParam]);

  return (
    <>
      <Container className="d-flex justify-content-between">
        <div>{selectedBookkeeping?.description}</div>
        <div>{selectedAccountingPeriod?.name}</div>
      </Container>
      <Container className="mt-4 d-flex justify-content-between mb-3">
        <b>{t("NAV_WAKKAKUITTI")}</b>
      </Container>
      <Container className="d-flex justify-content-between">
        <TakePhoto setReceiptImage={setReceiptImage} />
      </Container>
      <Container
        className="d-flex justify-content-between"
        style={{ marginTop: 20 }}
      >
        <b>{title}</b>
      </Container>
      <SaveReceiptModal
        isOpen={
          modalStatus === ModalStatus.Create ||
          modalStatus === ModalStatus.Edit ||
          modalStatus === ModalStatus.Open
        }
        setReceiptImage={setReceiptImage}
        receiptImage={receiptImage}
      />
    </>
  );
};

export default CommonTakePhotoHeader;
