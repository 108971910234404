import {
  ReceivedElectricReceiptDto,
  CreateReceivedElectricReceiptFileCommand,
  MoveElectricReceiptToNewBookkeepingCommand,
  UpdateElectricReceiptCommand,
} from "../generated/tsClient";
import {
  sendGetRequest,
  sendPostRequest,
  sendPatchRequest,
  sendDeleteRequest,
  sendGetBlobRequest,
} from "../utils/axiosClient";
import { store } from "../store/store";
import { setError } from "../store/slices/appSlice";
import {
  IReceiptFile,
  addCreatedElectricReceipt,
  setReceiptFile,
  setReceivedElectricReceipts,
  updateElectricReceipts,
} from "../store/slices/receiptSlice";
import i18n from "../i18n";

export async function fetchReceivedElectricReceipts(
  bookkeepingId: string,
  accountingPeriodId: string
): Promise<ReceivedElectricReceiptDto[]> {
  const response = await sendGetRequest(
    `api/v1/receipt/electricReceipts?bookkeepingId=${bookkeepingId}&accountingPeriodId=${accountingPeriodId}`,
    true
  ).catch((error) => {
    store.dispatch(
      setError(i18n.t("ERR_RECEIPTS_FAILED_TO_LOAD", { ns: "errors" }))
    );
    return Promise.reject();
  });

  if (response.status !== 200) {
    store.dispatch(
      setError(i18n.t("ERR_RECEIPTS_FAILED_TO_LOAD", { ns: "errors" }))
    );
    return Promise.reject();
  }

  store.dispatch(setReceivedElectricReceipts(response.data));

  return response.data;
}

export async function createReceivedElectricReceiptFile(
  command: CreateReceivedElectricReceiptFileCommand
): Promise<ReceivedElectricReceiptDto> {
  const response = await sendPostRequest(
    `api/v1/receipt/electricReceiptFile`,
    command,
    "application/json",
    true
  ).catch((error) => {
    if (error?.response?.data?.errors?.message?.[0] === 'Maximum file size is 10 MB') {
          store.dispatch(setError(i18n.t('ERR_MAXFILESIZE', { ns: 'errors' })));
        return Promise.reject();
    }

    store.dispatch(setError(i18n.t("ERR_FAILED_TO_SAVE", { ns: "errors" })));
    return Promise.reject();
  });

  if (response?.status !== 200) {
    store.dispatch(setError(i18n.t("ERR_FAILED_TO_SAVE", { ns: "errors" })));
    return Promise.reject();
  }

  store.dispatch(addCreatedElectricReceipt(response.data));

  return response.data;
}

export async function moveElectricReceiptToNewBookkeeping(
  command: MoveElectricReceiptToNewBookkeepingCommand
): Promise<ReceivedElectricReceiptDto[]> {
  const response = await sendPostRequest(
    `api/v1/receipt/moveElectricReceiptsToNewBookkeeping`,
    command,
    "application/json",
    true
  ).catch((error) => {
    store.dispatch(setError(i18n.t("ERR_FAILED_TO_MOVE", { ns: "errors" })));
    return Promise.reject();
  });

  if (response.status !== 200) {
    store.dispatch(setError(i18n.t("ERR_FAILED_TO_MOVE", { ns: "errors" })));
    return Promise.reject();
  }

  return response.data;
}

export async function deleteElectricReceipts(
  bookkeepingId: string,
  electricReceiptIds: number[]
): Promise<ReceivedElectricReceiptDto[]> {
  let deleteUrl = `api/v1/receipt/removeElectricReceipts?bookkeepingId=${bookkeepingId}`;
  electricReceiptIds.forEach((id) => {
    deleteUrl += `&electricReceiptIds=${id}`;
  });

  const response = await sendDeleteRequest(deleteUrl, true).catch((error) => {
    store.dispatch(setError(i18n.t("ERR_FAILED_TO_DELETE", { ns: "errors" })));
    return Promise.reject();
  });
 
  if (response.status !== 200) {
    store.dispatch(setError(i18n.t("ERR_FAILED_TO_DELETE", { ns: "errors" })));
    return Promise.reject();
  }

  return response.data;
}

export async function updateElectricReceipt(
  command: UpdateElectricReceiptCommand
): Promise<ReceivedElectricReceiptDto> {
  const response = await sendPatchRequest(
    `api/v1/receipt/updateElectricReceipt`,
    command,
    "application/json",
    true
  ).catch((error) => {
    if (error?.response?.data) {
      if (
        error?.response?.data?.errors?.message?.[0] ===
        "Cannot update row, receipt not found"
      ) {
        store.dispatch(setError(i18n.t("RECEIPT_NOT_FOUND", { ns: "errors" })));
        return Promise.reject();
      }

      if (
        error?.response?.data?.errors?.message?.[0] ===
        "Cannot update row, receipt status not new"
      ) {
        store.dispatch(setError(i18n.t("RECEIPT_NOT_NEW", { ns: "errors" })));
        return Promise.reject();
      }

      store.dispatch(setError(i18n.t("ERR_FAILED_TO_SAVE", { ns: "errors" })));
      return Promise.reject();
    }
  });

  if (response?.status !== 200) {
    store.dispatch(setError(i18n.t("ERR_FAILED_TO_SAVE", { ns: "errors" })));
    return Promise.reject();
  }

  store.dispatch(updateElectricReceipts(response.data));

  return response.data;
}

export async function fetchReceiptElectricReceiptFile(
  bookkeepingId: string,
  electricReceiptId: string
): Promise<void> {
  const response = await sendGetBlobRequest(
    `api/v1/receipt/electricReceiptFile?bookkeepingId=${bookkeepingId}&electricReceiptId=${electricReceiptId}`,
    true
  ).catch((error) => {
    store.dispatch(
      setError(i18n.t("ERR_RECEIPTS_FAILED_TO_LOAD", { ns: "errors" }))
    );
    return Promise.reject();
  });

  if (response.status !== 200) {
    store.dispatch(
      setError(i18n.t("ERR_RECEIPTS_FAILED_TO_LOAD", { ns: "errors" }))
    );
    return Promise.reject();
  }
  const fileData = new Blob([response.data], {
    type: response.headers["content-type"],
  });
  const contentDisposition = response.headers["content-disposition"];
  const fileNameMatch = contentDisposition
    ? /filename="?([^"]*)"?;/g.exec(contentDisposition)
    : undefined;
  const fileName =
    fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
  const blobURL = URL.createObjectURL(fileData);

  const receiptFile: IReceiptFile = {
    url: blobURL,
    contentType: response.headers["content-type"],
    fileName,
  };
  store.dispatch(setReceiptFile(receiptFile));

  return Promise.resolve();
}
