import axios, { AxiosResponse, AxiosHeaders } from "axios";
import { getAccessToken } from './auth';
import { setupInterceptorsTo } from "./axiosInterceptor";
setupInterceptorsTo(axios);

export async function sendGetRequest(url: string, withToken: boolean | undefined): Promise<AxiosResponse> {

    const token = withToken ? await getAccessToken() : null;
    const axiosHeaders = new AxiosHeaders({
      "Content-Type": "application/json",
      "X-LoadRelatedEntities": "1",
    });

  if (token) {
      axiosHeaders.setAuthorization("Bearer " + token);
  }

  return axios.get(url, {
      headers: axiosHeaders,
      timeout: 300000,
  });
}

export async function sendGetBlobRequest(url: string, withToken: boolean | undefined): Promise<AxiosResponse> {

    const token = withToken ? await getAccessToken() : null;

    const axiosHeaders = new AxiosHeaders({
        "Content-Type": "application/json",
        "X-LoadRelatedEntities": "1",
    });
    if (token) {
      axiosHeaders.setAuthorization("Bearer " + token);
    }

    return axios.get(url, {
        headers: axiosHeaders,
        timeout: 300000,
        responseType: "blob",
    });
}


export async function sendPostRequest(url: string, body: any, contentType: string | undefined, withToken: boolean | undefined): Promise<AxiosResponse> {

    const token = withToken ? await getAccessToken() : null;

    const axiosHeaders = new AxiosHeaders({
      "Content-Type": contentType ?? "application/json",
      "X-LoadRelatedEntities": "1",
    });
    if (token) {
      axiosHeaders.setAuthorization("Bearer " + token);
    }

    return axios.post(url, body, {
        headers: axiosHeaders,
        timeout: 300000,
    });
}

export async function sendPatchRequest(url: string, body: any, contentType: string | undefined, withToken: boolean | undefined): Promise<AxiosResponse> {

    const token = withToken ? await getAccessToken() : null;

    const axiosHeaders = new AxiosHeaders({
      "Content-Type": contentType ?? "application/json",
      "X-LoadRelatedEntities": "1",
    });
    if (token) {
      axiosHeaders.setAuthorization("Bearer " + token);
    }

    return axios.patch(url, body, {
        headers: axiosHeaders,
        timeout: 300000,
    });
}

export async function sendDeleteRequest(url: string, withToken: boolean | undefined): Promise<AxiosResponse> {

    const token = withToken ? await getAccessToken() : null;

    const axiosHeaders = new AxiosHeaders({
      "Content-Type": "application/json",
      "X-LoadRelatedEntities": "1",
    });
    if (token) {
      axiosHeaders.setAuthorization("Bearer " + token);
    }

    return axios.delete(url, {
        headers: axiosHeaders,
        timeout: 300000,
    });
}
