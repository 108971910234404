import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookkeepingSettingsDto } from '../../generated/tsClient';
import { ModalStatus } from '../../types/ModalStatusEnum';

export interface IBookkeepingSettingsState {
    bookkeepingSetting: BookkeepingSettingsDto | null,
    modalStatus: ModalStatus,
    fieldDisabled: boolean,
    selectedRow: number,
    bookkeepingSettingAddedOrRemoved: boolean,
    selectedBusinessId: string | null
}

const initialState: IBookkeepingSettingsState = {
    bookkeepingSetting: null,
    modalStatus: ModalStatus.Closed,
    fieldDisabled: false,
    selectedRow: 0,
    bookkeepingSettingAddedOrRemoved: false,
    selectedBusinessId: null
}

export const bookkeepingSettingsSlice = createSlice({
    name: 'bookkeepingSettings',
    initialState: initialState,
    reducers: {
        setBookkeepingSetting: (state, action: PayloadAction<BookkeepingSettingsDto>) => {
            state.bookkeepingSetting = action.payload;
        },
        resetInitialState: (state) => {
            state.bookkeepingSetting = initialState.bookkeepingSetting;
        },
        setModalStatus: (state, action: PayloadAction<ModalStatus>) => {
            state.modalStatus = action.payload;
        },
        openAddModal: (state) => {
            state.bookkeepingSetting = initialState.bookkeepingSetting;
            state.modalStatus = ModalStatus.Open;
            state.fieldDisabled = false;
        },
        setFieldDisabled: (state, action: PayloadAction<boolean>) => {
            state.fieldDisabled = action.payload;
        },
        setSelectedRow: (state, action: PayloadAction<number>) => {
            state.selectedRow = action.payload;
        },
        setBankAccountAddedOrRemoved: (state, action: PayloadAction<boolean>) => {
            state.bookkeepingSettingAddedOrRemoved = action.payload;
        },
        setSelectedBusinessId: (state, action: PayloadAction<string>) => {
            state.selectedBusinessId = action.payload;
        },
    }
})

export const { setBookkeepingSetting, resetInitialState, setModalStatus, openAddModal, setFieldDisabled, setSelectedRow, setSelectedBusinessId } = bookkeepingSettingsSlice.actions;

export default bookkeepingSettingsSlice.reducer;
