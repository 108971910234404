import Backdrop from "@mui/material/Backdrop";
import { useState } from "react";
interface Props {
  imageURL: string;
}
const ImagePreviewBackdrop = ({ imageURL }: Props) => {
  const [openImage, setOpenImage] = useState(false);
  const handleCloseImage = () => {
    setOpenImage(false);
  };
  const handleOpenImage = () => {
    setOpenImage(true);
  };
  return (
    <>
      <img
        alt="receipt"
        src={imageURL}
        className="imagePreview"
        onClick={handleOpenImage}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openImage}
        onClick={handleCloseImage}
      >
        <img
          alt="receipt"
          src={imageURL}
          className="fullScreenImage"
        />
      </Backdrop>
    </>
  );
};

export default ImagePreviewBackdrop;
