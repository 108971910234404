import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import CommonTakePhotoHeader from "../components/CommonTakePhotoHeader";
import { ReceivedElectricReceiptStatus } from "../../generated/tsClient";

import { ChangeEvent, useEffect } from "react";
import CommonReceiptsList from "../components/CommonReceiptsList";
import { Box, Button, Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SelectActionModal from "../modals/SelectActionModal";
import { ModalStatus } from "../../types/ModalStatusEnum";
import {
  setModalStatus,
  setSelectedReceipts,
} from "../../store/slices/receiptSlice";

const NewReceipts = () => {
  const { t } = useTranslation(["texts", "errors"]);
  const dispatch = useAppDispatch();

  const receivedElectricReceipts = useAppSelector(
    (state) => state.receipts.receivedElectricReceipts
  );
  const newElectricReceipts = receivedElectricReceipts.filter(
    (r) => r.status === ReceivedElectricReceiptStatus.New
  );
  const modalStatus = useAppSelector((state) => state.receipts.modalStatus);

  const selectedReceipts = useAppSelector(
    (state) => state.receipts.selectedReceipts
  );

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const value = parseInt(event.target.value, 10);
    let newChecked: number[] = [];

    if (checked) {
      newChecked = [...(selectedReceipts ?? []), value];
    } else {
      newChecked = selectedReceipts?.filter((id) => id !== value);
    }

    dispatch(setSelectedReceipts(newChecked));
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedReceipts([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="py-3 px-1">
      <CommonTakePhotoHeader title={t("HOME_NEW_RECEIPTS")} receiptImageParam={null} />
      <Container className="d-flex justify-content-between">
        <Button
          type="button"
          variant="text"
          size="small"
          onClick={() => dispatch(setSelectedReceipts([]))}
        >
          {t("DESELECT_ALL")}
        </Button>
        <Typography
          variant="button"
          display="inline-flex"
          sx={{ color: "#1976d2", alignItems: "center", fontSize: "0.8125rem" }}
        >
          {t("SELECTED_AMOUNT", { amount: selectedReceipts?.length ?? 0 })}
        </Typography>
      </Container>
      <CommonReceiptsList
        receipts={newElectricReceipts}
        selectedReceipts={selectedReceipts}
        handleToggle={handleToggle}
      />
      <Box className="fab">
        <Fab
          onClick={() =>
            dispatch(setModalStatus(ModalStatus.SelectActionModal))
          }
          disabled={selectedReceipts?.length < 1}
          variant="extended"
          size="large"
          color="primary"
          aria-label="add"
        >
          <AddIcon sx={{ mr: 1 }} />
          {t("ACTION_FOR_SELECTED")}
        </Fab>
      </Box>
      <SelectActionModal
        isOpen={modalStatus === ModalStatus.SelectActionModal}
      />
    </Container>
  );
};

export default NewReceipts;
