import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { useAppSelector } from "../../store/hooks";
import CommonTakePhotoHeader from "../components/CommonTakePhotoHeader";
import { ReceivedElectricReceiptStatus } from "../../generated/tsClient";

import { ChangeEvent, useEffect, useState } from "react";
import CommonReceiptsList from "../components/CommonReceiptsList";

const MovedToBookkeepingReceipts = () => {
  const { t } = useTranslation(["texts", "errors"]);
  const receivedElectricReceipts = useAppSelector(
    (state) => state.receipts.receivedElectricReceipts
  );

  const movedToBookkeepingElectricReceipts = receivedElectricReceipts.filter(
    (r) => r.status === ReceivedElectricReceiptStatus.Transferred
  );

  const [selectedReceipts, setSelectedReceipts] = useState<number[]>([]);

  const handleToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const value = parseInt(event.target.value, 10);
    let newChecked: number[] = [];

    if (checked) {
      newChecked = [...selectedReceipts, value];
    } else {
      newChecked = selectedReceipts.filter((id) => id !== value);
    }

    setSelectedReceipts(newChecked);
  };

  useEffect(() => {
    return () => {
      setSelectedReceipts([]);
    };
  }, []);

  return (
    <Container className="py-3 px-1">
      <CommonTakePhotoHeader title={t("HOME_MOVED_TO_BOOKKEEPING")} receiptImageParam={null} />
      <CommonReceiptsList
        receipts={movedToBookkeepingElectricReceipts}
        selectedReceipts={selectedReceipts}
        handleToggle={handleToggle}
        receiptsStatusIsNotNew={true}
      />
    </Container>
  );
};

export default MovedToBookkeepingReceipts;
