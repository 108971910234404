import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/hooks";
import { Button } from "@mui/material";
import AddAPhotoOutlined from "@mui/icons-material/AddAPhotoOutlined";
import {
  IReceiptImage,
  setModalStatus,
  setReceiptFormField,
} from "../../store/slices/receiptSlice";
import { fileToBase64 } from "../../utils/common";
import { toast } from "react-toastify";
import { ModalStatus } from "../../types/ModalStatusEnum";
import "dayjs/locale/fi";
import "dayjs/locale/sv";
import dayjs from "dayjs";
interface Props {
  setReceiptImage: (receiptImage: IReceiptImage | null) => void;
}
function TakePhoto({ setReceiptImage }: Props) {
  const { t } = useTranslation(["texts", "errors"]);
  const dispatch = useAppDispatch();
  const photoRef = useRef<HTMLInputElement>(null);

  const handleImageChange = async (event: any) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    const image = event.target.files[0];
    const imageAsBase64String = (await fileToBase64(image)) as string;
    const strParts = imageAsBase64String.split("base64,");
    const mimeType = strParts[0].replace("data:", "").replace(";", "");
    const fileSize: number = image.size;
    if (
      !strParts[0].includes("image/") &&
      !strParts[0].includes("application/pdf")
    ) {
      toast.error<unknown>(t("ERR_FILECONTENTNOTSUPPORTED", { ns: "errors" }));
      return;
    }

    if (fileSize >= maxSizeInBytes) {
      toast.error<unknown>(t("ERR_MAXFILESIZE", { ns: "errors" }));
      return;
    } else {
      let imagePreview: string | undefined = undefined;
      if (strParts[0].includes("image/")) {
        imagePreview = URL.createObjectURL(image);
      }
      setReceiptImage({
        imageFileName: image.name,
        imagePreview: imagePreview,
        imageFileBase64: strParts[1],
        mimeType: mimeType,
      });
      dispatch(
        setReceiptFormField({ paymentDate: dayjs(new Date()).toDate() })
      );
      dispatch(setModalStatus(ModalStatus.Create));
    }
  };

  const handleClick = () => {
    photoRef?.current?.click();
  };

  return (
    <div>
      <input
        style={{ display: "none" }}
        type="file"
        accept="image/*,application/pdf"
        ref={photoRef}
        onChange={handleImageChange}
        onClick={(event) => {
          event.currentTarget.value = ""; // hack to trigger onChange even if same file is selected again
        }}
      />
      <Button
        variant="contained"
        className="py-3"
        startIcon={<AddAPhotoOutlined />}
        onClick={handleClick}
      >
        {t("WAKKAKUITTI_TAKEPHOTO")}
      </Button>
    </div>
  );
}

export default TakePhoto;
