import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import { Container } from 'reactstrap';
import Header from './components/Header';
import 'react-toastify/dist/ReactToastify.css';
import './Styles.css';

interface IProps {
  children: React.ReactNode;
}

function Layout(props: IProps) {

  return (
      <div className="backgroundDiv">
        <div className="mainDiv">
          <Header />
          <ToastContainer
            theme='colored'
            position='top-center'
            autoClose={false}
            transition={Slide}
          />
          <Container className="p-0" fluid>
            {props.children}
          </Container>
        </div>
      </div>
    )
}

export default Layout;