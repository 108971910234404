import {
    BookkeepingDto, BookkeepingSettingsDto, UserDataDto
} from '../generated/tsClient';
import { sendGetRequest } from '../utils/axiosClient';
import { store } from '../store/store';
import {
    setBookkeepings,
    setBookkeepingSettings
} from '../store/slices/bookkeepingSlice';
import { setBookkeepingSetting as setSelectedInBookkeepingSettings} from '../store/slices/bookkeepingSettingsSlice';
import { setError } from '../store/slices/appSlice';
import i18n from '../i18n';

export async function fetchBookkeepings(userData: UserDataDto): Promise<BookkeepingDto[]> {

    const response = await sendGetRequest('api/v1/bookkeeping/', true)
    .catch(error => {
      store.dispatch(setError(i18n.t('ERR_BOOKKEEPINGS_FAILED_TO_LOAD', { ns: 'errors' })));
      return Promise.reject();
    });

    if (response.status !== 200) {
      store.dispatch(setError(i18n.t('ERR_BOOKKEEPINGS_FAILED_TO_LOAD', { ns: 'errors' })));
      return Promise.reject();
    }
    
    store.dispatch(setBookkeepings(response.data));

    return response.data;
}

export async function fetchBookkeepingSettings(bookkeepingId: string): Promise<BookkeepingSettingsDto> {
    const response = await sendGetRequest('api/v1/bookkeeping/settings?bookkeepingId=' + bookkeepingId, true)
        .catch(error => {
            store.dispatch(setError(i18n.t('ERR_BOOKKEEPING_SETTINGS_FAILED_TO_LOAD', { ns: 'errors' })));
            return Promise.reject();
        });

    if (response.status !== 200) {
        store.dispatch(setError(i18n.t('ERR_BOOKKEEPING_SETTINGS_FAILED_TO_LOAD', { ns: 'errors' })));
        return Promise.reject();
    }

    store.dispatch(setBookkeepingSettings(response.data));

    return response.data;
}

export async function fetchSelectedBookkeepingInBookkeepingSettings(bookkeepingId: string): Promise<BookkeepingSettingsDto> {
    const response = await sendGetRequest('api/v1/bookkeeping/settings?bookkeepingId=' + bookkeepingId, true)
        .catch(error => {
            store.dispatch(setError(i18n.t('ERR_BOOKKEEPING_SETTINGS_FAILED_TO_LOAD', { ns: 'errors' })));
            return Promise.reject();
        });

    if (response.status !== 200) {
        store.dispatch(setError(i18n.t('ERR_BOOKKEEPING_SETTINGS_FAILED_TO_LOAD', { ns: 'errors' })));
        return Promise.reject();
    }

    store.dispatch(setSelectedInBookkeepingSettings(response.data));

    return response.data;
}
