import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/hooks";
import { Button } from "@mui/material";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import {
  IReceiptImage,
  IReceiptImageWithDimensions,
  setModalStatus,
  setReceiptFormField
} from "../../store/slices/receiptSlice";
import { fileToBase64 } from "../../utils/common";
import { toast } from "react-toastify";
import { ModalStatus } from "../../types/ModalStatusEnum";
import "dayjs/locale/fi";
import "dayjs/locale/sv";
import dayjs from "dayjs";
import { IMAGE_MAXSIZEBYTES } from "../../types/constants";
import { generatePdfFromImages } from "../../utils/pdfUtil";
import { format } from 'date-fns'

interface Props {
  setReceiptImage: (receiptImage: IReceiptImage | null) => void;
}

function CreateMultiPageReceipt({ setReceiptImage }: Props) {
  const { t } = useTranslation(["texts", "errors"]);
  const dispatch = useAppDispatch();
  const photoRef = useRef<HTMLInputElement>(null);

  const handleImageChange = async (event: any) => {
    const imageFiles: File[] = event.target.files ? Array.from(event.target.files) : [];
    const receiptImages: IReceiptImageWithDimensions[] = [];

    for (const image of imageFiles) {
      if (!image.type.includes("image/")) {
        toast.error<unknown>(t("ERR_FILECONTENTNOTSUPPORTED", { ns: "errors" }));
        return;
      }

      const fileSize: number = image.size;
      if (fileSize >= IMAGE_MAXSIZEBYTES) {
        toast.error<unknown>(t("ERR_MAXFILESIZE", { ns: "errors" }));
        return;
      }

      const imageUrl = URL.createObjectURL(image);
      await getImageWithDimensions(imageUrl).then((imageObj: any) => {
        receiptImages.push({
          imageFileName: image.name,
          imagePreview: imageObj.src,
          imageFileBase64: "",
          mimeType: image.type,
          width: imageObj.width,
          height: imageObj.height
        });
      })
    };

    if (receiptImages) {
      const pdfBlob = generatePdfFromImages(receiptImages);

      if (pdfBlob) {
        const currentDate = dayjs(new Date()).toDate();
        const dateString = format(currentDate, "yyyy-dd-MM_HH-mm-ss");
        const pdfFileName = `WakkaKuitti_${dateString}.pdf`;
        const pdfFile = new File([pdfBlob], pdfFileName);

        if (pdfFile.size >= IMAGE_MAXSIZEBYTES) {
          toast.error<unknown>(t("ERR_MAXFILESIZE", { ns: "errors" }));
          return;
        }

        const pdfAsBase64String = (await fileToBase64(pdfFile)) as string;
        const base64Parts = pdfAsBase64String.split("base64,");

        setReceiptImage({
          imageFileName: pdfFileName,
          imagePreview: undefined,
          imageFileBase64: base64Parts[1],
          mimeType: "application/pdf"
        });
        dispatch(
          setReceiptFormField({ paymentDate: currentDate })
        );
        dispatch(setModalStatus(ModalStatus.Create));
      }
    }
  };

  const handleClick = () => {
    photoRef?.current?.click();
  };

  const getImageWithDimensions = async (url: string) => {
    const img = document.createElement("img");

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const src = url;
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        resolve({ src, width, height });
      };

      img.src = url;
      img.onerror = reject;
    });

    return promise;
  }

  return (
    <div>
      <input
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        ref={photoRef}
        onChange={handleImageChange}
        onClick={(event) => {
          event.currentTarget.value = ""; // hack to trigger onChange even if same file is selected again
        }}
        multiple
      />
      <Button
        variant="contained"
        className="py-3 mt-5"
        startIcon={<PhotoLibraryIcon />}
        onClick={handleClick}
      >
        {t("WAKKAKUITTI_CREATE_MULTIPAGE_RECEIPT")}
      </Button>
    </div>
  );
}

export default CreateMultiPageReceipt;
