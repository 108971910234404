import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { setAuthenticated, setToken, showLoginError } from '../../store/slices/loginSlice';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  checkTokenIssuer, getAccessTokenWithAuthCode,
  getIdentityConfiguration, startAuthCodeFlow
} from '../../utils/auth';
import { fetchUserData } from '../../services/userService';
import Loader from '../components/Loader';
import { fetchBookkeepings } from "../../services/bookkeepingService";
import { UserDataDto } from "../../generated/tsClient";

function Login(props: any) {

  const { t } = useTranslation(['texts', 'errors']);
  const isAuthenticated = useAppSelector((state) => state.login.authenticated);
  const isLoading = useAppSelector((state) => state.app.loading);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const loginWithAuthCode = () => {
    const identityConfiguration = getIdentityConfiguration();
    const redirectToLogin = async () => {
      const { authorizeUrl, verifier } = await startAuthCodeFlow(identityConfiguration);
      localStorage.setItem("mobiwakka_verifier", verifier);
      window.location.replace(authorizeUrl);
    }
    redirectToLogin();
  }

  const getTokenWithAuthCode = (authCode: string) => {
    const verifier = localStorage.getItem("mobiwakka_verifier") as string;
    if (!verifier) {
      return;
    }
    localStorage.removeItem("mobiwakka_verifier");

    const fetchToken = async () => {
      const identityConfiguration = getIdentityConfiguration();
      const token = await getAccessTokenWithAuthCode(authCode, verifier, identityConfiguration);
      if (token.accessToken && checkTokenIssuer(token.accessToken)) {
        dispatch(setToken(token));

        var userData: UserDataDto | undefined;
        await fetchUserData()
          .then((response) => {
            userData = response;
          })
          .catch(() => {
            userData = undefined;
          });

        if (userData) {
          toast.success(`${t('LOGIN_SUCCESS')} ${userData.fullName}`, { autoClose: 2000 });
          dispatch(setAuthenticated(userData));
        } else {
          dispatch(showLoginError());
          navigate('/logout');
        }

        if (userData?.customerId) {
            await fetchBookkeepings(userData);
        }
      }
    }

    fetchToken();
  }

  if (isAuthenticated) {
    return (
      <Navigate to="/" replace={true} />
    )
  }
  else if (isLoading) {
    return (
      <Loader />
    )
  } else {
    if (location.hash.includes('error')) {
      return (
        <Navigate to="/?state=loginFailed" replace={true} />
      )
    }

    const params = new URLSearchParams(location.search);

    if (params.has('code')) {
      getTokenWithAuthCode(params.get('code') as string);
    } else {
      loginWithAuthCode();
    }

    return (
      <Loader />
    )
  }
  
}

export default Login;
