import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { ModalStatus } from "../../types/ModalStatusEnum";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setIsLoading,
  setModalStatus,
  setSelectedReceipts,
} from "../../store/slices/receiptSlice";
import { useTranslation } from "react-i18next";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  formatDateToString,
  formatNumberWithCommaDecimalSeparator,
  getAutoCloseTime,
} from "../../utils/common";
import { useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Fab,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  deleteElectricReceipts,
  fetchReceivedElectricReceipts,
  moveElectricReceiptToNewBookkeeping,
} from "../../services/receiptService";
import { MoveElectricReceiptToNewBookkeepingCommand } from "../../generated/tsClient";
import { toast } from "react-toastify";
interface Props {
  isOpen: boolean;
}
const SelectActionModal = ({ isOpen }: Props) => {
  const { t } = useTranslation(["texts", "errors"]);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.receipts.isLoading);
  const selectedReceipts = useAppSelector(
    (state) => state.receipts.selectedReceipts
  );
  const currentBookkeeping = useAppSelector(
    (state) => state.bookkeeping.bookkeeping
  );
  const bookkeepings = useAppSelector(
    (state) => state.bookkeeping.bookkeepings
  );
  const selectedAccountingPeriod = useAppSelector(
    (state) => state.accountingPeriods.selectedPeriod
  );
  const [selectedBookkeeping, setSelectedBookkeeping] = useState<
    number | undefined
  >();
  const filteredBookkeepings = useMemo(() => {
    return bookkeepings?.filter(
      (bookkeeping) => bookkeeping.id !== currentBookkeeping?.id
    );
  }, [bookkeepings, currentBookkeeping]);

  const handleBookkeepingChange = (event: SelectChangeEvent) => {
    setSelectedBookkeeping(parseInt(event.target.value, 10));
  };

  const receivedElectricReceipts = useAppSelector(
    (state) => state.receipts.receivedElectricReceipts
  );
  const selectedNewElectricReceipts = useMemo(
    () =>
      receivedElectricReceipts.filter(
        (receipt) =>
          receipt.electricReceiptId &&
          selectedReceipts?.includes(receipt.electricReceiptId)
      ),
    [selectedReceipts, receivedElectricReceipts]
  );

  const onClose = () => {
    setSelectedBookkeeping(undefined);
    dispatch(setModalStatus(ModalStatus.Closed));
  };

  const onMoveElectricReceiptsToNewBookkeeping = () => {
    const command = new MoveElectricReceiptToNewBookkeepingCommand();
    command.bookkeepingId = currentBookkeeping?.id;
    command.newBookkeepingId = selectedBookkeeping;
    command.electricReceiptIds = selectedReceipts;
    dispatch(setIsLoading(true));
    moveElectricReceiptToNewBookkeeping(command)
      .then(() => {
        toast.success(`${t("SAVE_SUCCESS")}`, {
          autoClose: getAutoCloseTime(),
        });
        fetchReceivedElectricReceipts(
          currentBookkeeping?.id?.toString() as string,
          selectedAccountingPeriod?.periodId?.toString() as string
        );
        dispatch(setSelectedReceipts([]));
        onClose();
      })
      .finally(() => dispatch(setIsLoading(false)));
  };

  const onRemoveElectricReceipts = () => {
    if (!currentBookkeeping?.id) {
      toast.error<unknown>(t("ERR_FAILED_TO_SAVE", { ns: "errors" }));
      return;
    }
    dispatch(setIsLoading(true));
    deleteElectricReceipts(currentBookkeeping?.id?.toString(), selectedReceipts)
      .then(() => {
        toast.success(`${t("REMOVE_SUCCESS")}`, {
          autoClose: getAutoCloseTime(),
        });
        fetchReceivedElectricReceipts(
          currentBookkeeping?.id?.toString() as string,
          selectedAccountingPeriod?.periodId?.toString() as string
        );
        dispatch(setSelectedReceipts([]));
        onClose();
      })
      .finally(() => dispatch(setIsLoading(false)));
  };

  return (
    <Modal isOpen={isOpen} id="modal.saveReceiptModal" fullscreen>
      <ModalHeader toggle={onClose}>{t("SELECT_ACTION")}</ModalHeader>
      <ModalBody>
        {bookkeepings?.length > 1 && (
          <>
            <Row>
              <Col>
                <InputLabel
                  sx={{
                    marginLeft: "1px",
                    marginBottom: "5px",
                    color: "#000000",
                  }}
                  shrink={false}
                  id="label.settings.bookkeeping"
                >
                  {t("LABEL_BOOKKEEPING")}
                </InputLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  label="label.settings.bookkeeping"
                  value={
                    selectedBookkeeping?.toString() ?? t("SELECT_BOOKKEEPING")
                  }
                  onChange={handleBookkeepingChange}
                  fullWidth={true}
                  notched={false}
                  disabled={isLoading}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#99C2FF",
                      borderWidth: "2px",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#99C2FF",
                    },
                  }}
                  id="bookkeeping-select"
                >
                  {filteredBookkeepings?.map((bookkeeping) => (
                    <MenuItem
                      key={bookkeeping.id?.toString()}
                      value={bookkeeping.id?.toString()}
                    >
                      {bookkeeping.description}
                    </MenuItem>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row className="mt-2 mb-4">
              <Col>
                <Button
                  variant="contained"
                  size="medium"
                  type="button"
                  onClick={onMoveElectricReceiptsToNewBookkeeping}
                  disabled={!selectedBookkeeping || isLoading}
                >
                  {isLoading ? (
                    <Spinner
                      style={{ width: "1.5rem", height: "1.5rem" }}
                      color="light"
                    />
                  ) : (
                    t("MOVE_TO_SELECTED_BOOKKEEPING")
                  )}
                </Button>
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col>
            <InputLabel
              sx={{ marginLeft: "1px", marginBottom: "5px", color: "#000000" }}
              shrink={false}
              id="label.selectActionModal.deleteTitle"
            >
              {t("DELETE_SELECTED_RECEIPTS_FROM_APP")}
            </InputLabel>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col>
            <Button
              variant="contained"
              size="medium"
              type="button"
              onClick={onRemoveElectricReceipts}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  color="light"
                />
              ) : (
                t("DELETE_SELECTED_RECEIPTS")
              )}
            </Button>
          </Col>
        </Row>
        <Accordion defaultExpanded sx={{ backgroundColor: "#FAFAFA" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {t("SHOW_SELECTED_RECEIPTS", {
                amount: selectedNewElectricReceipts.length,
              })}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            id="selected-receipts-list"
            aria-controls="Selected receipts list"
            sx={{ padding: 0 }}
          >
            <DataGridPro
              rowHeight={35}
              autoHeight
              hideFooter
              disableColumnMenu
              disableColumnReorder
              disableColumnFilter
              disableChildrenSorting
              disableMultipleColumnsSorting
              density="compact"
              sx={{
                border: "none",

                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                  fontSize: 11,
                },

                "& .MuiDataGrid-cell": {
                  padding: "0px 2px 0px 2px",
                  fontSize: 11,
                },
                backgroundColor: "background.paper",
              }}
              columns={[
                {
                  field: "paymentDate",
                  headerName: t("LABEL_DATE"),
                  type: "string",
                  align: "center",
                  width: 70,
                  sortable: false,
                  valueGetter: (params) => {
                    if (!params.value) {
                      return "";
                    }
                    return formatDateToString(params.value);
                  },
                },
                {
                  field: "description",
                  headerName: t("SAVERECEIPT_DESCRIPTION"),
                  type: "string",
                  align: "left",
                  width: 160,
                  sortable: false,
                },
                {
                  field: "price",
                  headerName: t("LABEL_PRICE"),
                  type: "string",
                  align: "left",
                  cellClassName: "dataGridPriceCell",
                  flex: 1,
                  sortable: false,
                  valueGetter: (params) => {
                    return (
                      formatNumberWithCommaDecimalSeparator(
                        params.row.prices?.[0]?.price ?? 0
                      ) ?? ""
                    );
                  },
                },
              ]}
              rows={selectedNewElectricReceipts.map((receipt) => ({
                ...receipt,
                id: receipt.electricReceiptId,
              }))}
            />
          </AccordionDetails>
        </Accordion>
      </ModalBody>
      <Box
        sx={{
          "& > :not(style)": {
            m: 1,
          },
          position: "absolute",
          bottom: 2,
          right: 16,
        }}
      >
        <Fab
          onClick={onClose}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="close"
        >
          {isLoading ? (
            <Spinner
              style={{ width: "1.5rem", height: "1.5rem" }}
              color="light"
            />
          ) : (
            t("CLOSE_BUTTON")
          )}
        </Fab>
      </Box>
    </Modal>
  );
};

export default SelectActionModal;
