import { useTranslation } from "react-i18next";
import { Col, Container, Row, Spinner } from "reactstrap";

function Loader(props: any) {

  const { t } = useTranslation(['texts', 'errors']);

  return (
    <Container className="text-center align-items-center">
      <Row>
        <Col>
          <Spinner animation="border" className="mt-4" />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {t('LOADING')}
        </Col>
      </Row>
    </Container>
  )
}

export default Loader;
