export enum WakkaLicenses {
  Wakka = 'Wakka',
  WakkaPro = 'WakkaPro',
  WakkaKuitti = 'WakkaKuitti',
  WakkaReports = 'WakkaReports',
  FreeOfChargeWakka = 'FreeOfChargeWakka'
}

export enum LicenseRequirementType {
  Any, // Requires at least one of the listed licenses
  All // Requires all of the listed licenses
}

export interface LicenseRequirements {
  requirementType: LicenseRequirementType,
  licenses: WakkaLicenses[]
}