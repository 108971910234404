import { AccountingPeriodDto } from '../generated/tsClient';
import { sendGetRequest } from '../utils/axiosClient';
import { store } from '../store/store';
import { setPeriod, setPeriods } from '../store/slices/accountingPeriodsSlice';
import { setError } from '../store/slices/appSlice';
import i18n from '../i18n';


export async function fetchAccountingPeriods(bookkeepingId: string): Promise<AccountingPeriodDto[]> {

    const response = await sendGetRequest('api/v1/accountingperiods?bookkeepingId=' + bookkeepingId, true)
        .catch(error => {
            store.dispatch(setError(i18n.t('ERR_ACCOUNTING_PERIODS_FAILED_TO_LOAD', { ns: 'errors' })));
            return Promise.reject();
        });

    if (response.status !== 200) {
        store.dispatch(setError(i18n.t('ERR_ACCOUNTING_PERIODS_FAILED_TO_LOAD', { ns: 'errors' })));
        return Promise.reject();
    }

    store.dispatch(setPeriods(response.data));
    store.dispatch(setPeriod(response.data[0]));

    return response.data;
}
