import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserDataDto } from "../../generated/tsClient";
import { ITokenData } from "../../utils/auth";

export interface ILoginState {
  username: string,
  password: string,
  userData: IUserDataDto | null,
  authenticated: boolean,
  token: ITokenData | null,
  showLoginError: boolean
}

const initialState: ILoginState = {
  username: '',
  password: '',
  userData: null,
  authenticated: false,
  token: null,
  showLoginError: false
}

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<IUserDataDto>) => {
      state.authenticated = true;
      state.userData = action.payload;
    },
    setNotAuthenticated: (state) => {
      state.authenticated = false;
    },
    showLoginError: (state) => {
      state.showLoginError = true;
    },
    setToken: (state, action: PayloadAction<ITokenData>) => {
      state.token = action.payload;
      localStorage.setItem(
        "mobiwakka_access_token",
        action.payload.accessToken
      );
      localStorage.setItem(
        "mobiwakka_refresh_token",
        action.payload.refreshToken
      );
      if (action.payload.idToken) {
        localStorage.setItem("mobiwakka_id_token", action.payload.idToken);
      }
    },
    setUserData: (state, action: PayloadAction<IUserDataDto>) => {
      state.userData = action.payload;
    },
    logout: (state) => {
      localStorage.clear();
      state.username = '';
      state.password = '';
      state.userData = null;
      state.token = null;
      state.authenticated = false;
    }
  }
})

export const { setUsername, setPassword, setAuthenticated, setNotAuthenticated, showLoginError, setToken, setUserData, logout } = loginSlice.actions;

export default loginSlice.reducer;
