// axios interceptor to handle 401 error
import { store } from "../store/store";
import { logout } from "../store/slices/loginSlice";
import {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { setError } from "../store/slices/appSlice";
import i18n from "../i18n";

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const { response, code } = error;
  const { pathname } = window.location;

  if (code === "ECONNABORTED") {
    store.dispatch(setError(i18n.t("ERR_ECONNABORTED", { ns: "errors" })));
  }

  if (response && response.status === 401 && pathname !== "/login") {
    store.dispatch(logout());
  }
  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
