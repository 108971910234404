import { IUserDataDto } from "../generated/tsClient";
import { LicenseRequirements, LicenseRequirementType, WakkaLicenses } from "../types/WakkaLicenses";
import { format } from 'date-fns'

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function decimalToHex(decimal: any) {
  return ("0" + decimal.toString(16)).substr(-2);
}

export function sha256Encode(str: any) {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  return window.crypto.subtle.digest("SHA-256", data);
}

export function base64Encode(input: any) {
  var str = "";
  var bytes = new Uint8Array(input);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

export function fileToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function spacesToIban(iban: any) {

    const ibanSpace = iban.match(/.{1,4}/g);

    return ibanSpace.join(' ');
   
}

export function getAutoCloseTime() {
  
    return 1500;
}

export function verifyLicenses(requiredLicense: LicenseRequirements, userData: IUserDataDto | null) {

  let hasAnyLicense = false;
  let hasAllLicenses = true;

  requiredLicense.licenses.forEach((license) => {
    if (hasLicense(license, userData)) {
      hasAnyLicense = true;
    } else {
      hasAllLicenses = false;
    }
  });

  if (hasAnyLicense && requiredLicense.requirementType === LicenseRequirementType.Any) {
    return true;
  }
  if (hasAllLicenses && requiredLicense.requirementType === LicenseRequirementType.All) {
    return true;
  }

  return false;
}

export function hasLicense(license: string, userData: IUserDataDto | null) {

  switch (license) {
    case WakkaLicenses.Wakka:
      return userData?.hasWakka || userData?.hasWakkaPro;
    case WakkaLicenses.WakkaPro:
      return userData?.hasWakkaPro;
    case WakkaLicenses.WakkaKuitti:
      return userData?.hasWakkaKuitti;
    case WakkaLicenses.WakkaReports:
      return userData?.hasWakkaReports;
    case WakkaLicenses.FreeOfChargeWakka:
      return userData?.hasFreeOfChargeWakka;
    default:
      break;
  }

  return true;
}

export function getDateFromString(stringDate: string) {

    if (!stringDate) {
        return;
    }
    var date = new Date(stringDate);
    var dateFormatted = format(date, 'dd.MM.yyyy');

    return dateFormatted;
}

export function deepCopy(object: any) {
    return JSON.parse(JSON.stringify(object));
}

export function numberWithThousandSeparator(input: number, amountOfDecimal: number) {
    return input.toFixed(amountOfDecimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function convertUTCDateToLocalDate(date: Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export const parseString = (isInt: boolean, str: string): string => {
    str = str.replace(/ /g, '');
    if (isInt) return str;
    return str.replace(/,/g, '.');
};

export const numberAsString = (val?: number | null, decimalPlaces?: number | null, min?: number | null): string => {
    if (val == null && !min)
        return '';

    if (decimalPlaces)
        return numberWithThousandSeparator(val || min || 0, decimalPlaces).replace('.', ',')

    return (val || min || 0).toString();
}

export const stringAsNumber = (val: string, decimalPlaces?: number | null): number | null => {
    const parsedStr = parseString(!decimalPlaces, val);

    if (!decimalPlaces) {
        const parsedInt = parseInt(parsedStr);
        return isNaN(parsedInt) ? null : parsedInt;
    }

    const parsedFloat = parseFloat(parsedStr);
    return isNaN(parsedFloat) ? null : parsedFloat;
}

export const validateStringAsNumber = (value: string, decimalPlaces?: number | null, maxLength?: number): boolean => {
    const defaultMaxFieldLength: number = 11;
    const parsedValue = parseString(!decimalPlaces, value);
    const regEx = !decimalPlaces
        ? /^-?\d*$/
        : new RegExp('^-?\\d{1,' + ((maxLength ?? defaultMaxFieldLength) - decimalPlaces) + '}.?\\d{' + decimalPlaces + '}$');

    return regEx.test(parsedValue);
}

export const sortArrayByLanguage = (array: any[], lang: string) => {

    if (!array) return [];

    if (lang === "fi")
        return [...array].sort((a: any, b: any) => a.explanationFin?.localeCompare(b.explanationFin, 'fi'));
    else
        return [...array].sort((a: any, b: any) => a.explanationSwe?.localeCompare(b.explanationSwe, 'sv'));

}

export function formatNumberWithCommaDecimalSeparator(number: number): string {
    return number.toLocaleString('fi-FI', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
}

export function formatCurrency(value: number): string {
    return value.toLocaleString("fi-FI", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

export function formatCurrencyWithoutSeparator(value: number): string {
    return value.toLocaleString("fi-FI", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
    }).replace(".", ",");
};

export const formatDateToString = (date: Date | undefined) => {
  if (!date) return "";
  return format(new Date(date), "dd.MM.yyyy");
}