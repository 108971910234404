import { UserDataDto } from '../generated/tsClient';
import { sendGetRequest, sendDeleteRequest } from '../utils/axiosClient';
import { store } from '../store/store';
import { setError } from '../store/slices/appSlice';
import i18n from '../i18n';

export async function fetchUserData(): Promise<UserDataDto> {
  const response = await sendGetRequest('api/v1/user', true)
    .catch(error => {
      store.dispatch(setError(i18n.t('ERR_USER_DATA_FAILED_TO_LOAD', { ns: 'errors' })));
      return Promise.reject();
    });

  if (response.status !== 200) {
    store.dispatch(setError(i18n.t('ERR_USER_DATA_FAILED_TO_LOAD', { ns: 'errors' })));
    return Promise.reject();
  }

  return response.data;
}

export async function clearUserDataCache() {
    const response = await sendDeleteRequest('api/v1/user/clearuserdatacache', true)
        .catch(error => {
            
            if (error.response.status !== 401) {
                store.dispatch(setError(i18n.t('ERR_USER_DATA_FAILED_TO_CLEAR', { ns: 'errors' })));
                return Promise.reject();
                
            }
            
        });

    // 401 Not authorized is not a issue. 401 needs to be handled, because App calls logout twice.
    // Once when LogOut button is pressed and second time after returning automatically from Identity logout screen.
    if (response && response.status !== 200 && response.status !== 401) {
        store.dispatch(setError(i18n.t('ERR_USER_DATA_FAILED_TO_CLEAR', { ns: 'errors' })));
        return Promise.reject();
    }

    return Promise.resolve();
}
