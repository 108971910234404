import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountingPeriodDto } from '../../generated/tsClient';

export interface IAccountingPeriodsState {
    period: AccountingPeriodDto | null,
    selectedPeriod: AccountingPeriodDto | null,
    periods: AccountingPeriodDto[]
}

const initialState: IAccountingPeriodsState = {
    period: null,
    selectedPeriod: null,
    periods: []
}

export const accountingPeriodsSlice = createSlice({
    name: 'accountingperiods',
    initialState: initialState,
    reducers: {

        setPeriods: (state, action: PayloadAction<AccountingPeriodDto[]>) => {
            state.periods = action.payload;
        },

        setPeriod: (state, action: PayloadAction<AccountingPeriodDto>) => {
            state.period = action.payload;
        },

        setSelectedPeriod: (state, action: PayloadAction<AccountingPeriodDto | null>) => {
            state.selectedPeriod = action.payload;
        },

        resetInitialState: (state) => {
            state.period = initialState.period;
        },
    }
})

export const { setPeriods, setPeriod, setSelectedPeriod, resetInitialState } = accountingPeriodsSlice.actions;

export default accountingPeriodsSlice.reducer;
